<template>
	<div>
		<Menu v-if="showMenu" />
		<router-view></router-view>
	</div>
</template>

<script>
import Menu from '@/components/Menu';
export default {
	name: 'App',
	computed: {
		showMenu () {
			return !this.$route.meta.noMenu;
		}
	},
	components: {
		Menu
	},
}
</script>
