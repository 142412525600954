<template>
	<nav class="d-flex align-items-center sticky-top">
		<div class="container d-flex justify-content-between sticky-shrink mt-0 mb-0">
			<div class="d-flex align-items-center">
				<div class="logo mr-5">
					<router-link to="/">
						<Logo />
					</router-link>
				</div>
				<div class="pages">
					<!--<router-link to="/" v-if="!isAuthenticated">
						Features
					</router-link>
					<router-link to="/" v-if="!isAuthenticated">
						Pricing
					</router-link>-->
					<router-link to="/template" v-if="isAuthenticated">
						Template Builder
					</router-link>
				</div>
			</div>
			<div class="d-flex align-items-center" v-if="!isAuthenticated">
				<b-button variant="link" to="/login" class="mr-2">
					Login
				</b-button>
				<b-button to="/create-account" variant="primary">
					Sign Up
				</b-button>
			</div>
			<div class="d-flex align-items-center" v-else>
				<b-button variant="link" @click.prevent="logout" class="mr-2">
					Log Out
				</b-button>
			</div>
		</div>
	</nav>
</template>

<script>
import Logo from '@/assets/images/logo.svg';
export default {
	name: 'Menu',
	components: {
		Logo
	}
}
</script>

<style lang="scss" scoped>
	$shrunken-menu-height: 50;
	.container {
		max-width: var(--container-lg);
		margin: auto;
		padding: 0 1.5rem;
	}

	nav {
		top: -#{$shrunken-menu-height}px;
		height: #{$shrunken-menu-height * 2}px;
		background: white;
		font-size: 0.938rem;
		box-shadow: 0 4px 20px #EFE3E0;
		font-weight: var(--font-weight-bold);
		z-index: 999;
	}

	.sticky-shrink {
		position: sticky;
		height: #{$shrunken-menu-height}px;
		top: 0;
	}

	.logo {
		img {
			width: 10rem;	
		}
	}

	@media (max-width: 700px) {
		.pages {
			display: none;
		}
	}

	.pages a {
		color: inherit;
		&:not(:last-of-type) {
			margin-right: 1.5rem;
		}
	}
</style>
